import React, { Component, useState, useEffect } from "react";
import { Image, Container, Col, Row } from "react-bootstrap";
import Button from "./../../components/button/button";

import { navigate } from "gatsby";

//import ReactDOM from "react-dom"
import { useMediaQuery } from "react-responsive";
import { useFormik } from "formik";
import Header1 from "../../components/header1/header1";
import Paragraph from "../../components/paragraph/paragraph";
import { Hidden } from "@material-ui/core";

export default function Transit() {
  var surveyImage =
    "https://firebasestorage.googleapis.com/v0/b/carbon-down.appspot.com/o/assets%2Fpexels-dsd-975416%20(1).jpg?alt=media&token=59a3cdf1-784a-4fcc-9779-9f98741c4957";
  const formik = useFormik({
    initialValues: {
      username: null,
      password: null,
    },
    onSubmit: (values) => {
      if (
        (values.username == "BoardwalkAuto") &
        (values.password == "99b41ae")
      ) {
        navigate("/dealerportal/5828cc73cd8a20c4715599e295736ce7", {
          replace: false,
        });
      } else {
        alert("Please enter your provided username and password.");
      }
    },
  });

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Hidden smDown>
        <div style={{ flex: 6 }}>
          <img
            style={{ height: "100vh", width: "100%", objectFit: "cover" }}
            src={surveyImage}
          />
        </div>
      </Hidden>

      <div style={{ flex: 6, padding: 40 }}>
        <form marginionSubmit={formik.handleSubmit}>
          <Header1>Dealer Login</Header1>
          <Paragraph>Username</Paragraph>
          <input
            id="username"
            name="username"
            style={{
              width: "90%",
              height: "3vh",
              marginTop: 10,
            }}
            onChange={formik.handleChange}
            value={formik.values.username}
          />
          <Paragraph>Password</Paragraph>
          <input
            id="password"
            name="password"
            type="password"
            style={{
              width: "90%",
              height: "3vh",
              marginTop: 10,
            }}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </form>
        <div
          style={{
            paddingTop: 40,
          }}
        >
          <Button text={"Login"} onClick={formik.submitForm} />
        </div>
      </div>
    </div>
  );
}
